import Vue, { VNode } from "vue";
import VueJsonEditor from "vue-json-editor";
import {
  VCard,
  VCardText,
  VCol,
  VForm,
  VIcon,
  VRow,
  VSpacer,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTextField,
} from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

import { Actions, Getters, Modules } from "@/models/store";

import DialLogTimeline from "../DialLogTimeline";
import DialLogAlertList from "./DialLogAlertList";
import DialLogOverview from "./DialLogOverview";

const DialLogDetail = Vue.extend({
  data: () => ({
    dialLogData: {} as any,
    rawData: {},
    activeTab: 0,
    search: "",
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    errorAndWarningsCount(): number {
      const dialLogData: any = this.dialLogData;
      const errorCount = dialLogData.detail?.errors?.length || 0;
      const warningCount = dialLogData.detail?.warnings?.length || 0;

      return errorCount + warningCount;
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.AUDIO_RECORD_FETCH,
      Actions.DIAL_LOG_FETCH,
    ]),

    async getGuidDetail(): Promise<void> {
      const refs: any = this.$refs;
      this.rawData = await this.DIAL_LOG_FETCH({ guid: this.search });

      if (!this.rawData) {
        this.dialLogData = "404";
      } else {
        this.dialLogData = this.rawData;
        this.dialLogData.guid = this.search;
        refs.timeline?.handleFetchRecord();
      }
    },

    handleSearch() {
      this.getGuidDetail();
      this.setRouteQueries();
    },

    setRouteQueries() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          guid: this.search,
          tab: this.activeTab.toString(),
        },
      });
    },
  },

  created() {
    this.activeTab = Number.parseInt(
      this.$route.query.tab ? this.$route.query.tab.toString() : "0"
    );
    this.search = this.$route.query.guid
      ? this.$route.query.guid.toString()
      : "";
    if (this.search) {
      this.getGuidDetail();
    }
  },

  render(): VNode {
    const { dialLogData } = this;

    return (
      <div>
        <h2 class="text-h4 font-weight-bold primary--text mb-5 d-flex align-center">
          Conversation Details
        </h2>
        <VRow justify="center">
          <VCol>
            <VForm
              onSubmit={(e: Event) => {
                e.preventDefault();
                this.handleSearch();
              }}
            >
              <VTextField
                vModel={this.search}
                class="shrink mb-6 mx-5"
                outlined
                rounded
                dense
                hideDetails
                label="GUID"
                appendIcon="mdi-magnify"
                on={{
                  "click:append": () => this.handleSearch(),
                }}
              />
            </VForm>
          </VCol>
        </VRow>
        <VCard
          loading={
            this.IS_ACTION_FETCHING(Actions.DIAL_LOG_FETCH) ||
            this.IS_ACTION_FETCHING(Actions.AUDIO_RECORD_FETCH)
          }
          rounded="b-0"
        >
          {!dialLogData.detail ? (
            <VCardText>No conversation found.</VCardText>
          ) : (
            <div>
              <VTabs
                onChange={() => this.setRouteQueries()}
                vModel={this.activeTab}
                grow
                dark
                backgroundColor="primary"
                class="mb-3"
              >
                <VTab>Timeline</VTab>
                <VTab>Data</VTab>
                <VTab>Source</VTab>
                <VTab>
                  Errors{" "}
                  {this.errorAndWarningsCount > 0 && (
                    <span class="error white--text rounded-circle px-1 ml-1">
                      {this.errorAndWarningsCount}
                    </span>
                  )}
                </VTab>
              </VTabs>
              <VCardText>
                <VTabsItems vModel={this.activeTab}>
                  <VTabItem>
                    {dialLogData.detail?.speakFlow?.length > 0 ? (
                      <DialLogTimeline
                        key={dialLogData.guid}
                        ref="timeline"
                        data={dialLogData.detail.speakFlow}
                        startDate={dialLogData.detail.speakFlow[0].timeStamp}
                        hasRecord={
                          dialLogData.recordExists || dialLogData.hasRecord
                        }
                        guid={dialLogData.guid}
                      />
                    ) : (
                      <div>
                        <h3 class="text-h6 primary--text mb-2">
                          Conversation flow
                        </h3>
                        <VIcon color="error" class="mr-2">
                          mdi-alert-octagon
                        </VIcon>
                        Timeline is not available.
                      </div>
                    )}
                  </VTabItem>
                  <VTabItem>
                    <DialLogOverview dialLogData={this.dialLogData} />
                  </VTabItem>
                  <VTabItem>
                    <div class="d-flex align-center mb-3">
                      <h3 class="text-h6 primary--text">Conversation source</h3>
                      <VSpacer />
                    </div>
                    <VueJsonEditor vModel={this.rawData} mode="code" />
                  </VTabItem>
                  <VTabItem>
                    {this.errorAndWarningsCount > 0 ? (
                      <div>
                        {dialLogData.detail?.errors?.length > 0 && (
                          <DialLogAlertList
                            data={dialLogData.detail?.errors}
                            title="Errors"
                            titleColor="error"
                          />
                        )}
                        {dialLogData.detail?.warnings?.length > 0 && (
                          <DialLogAlertList
                            data={dialLogData.detail?.warnings}
                            title="Warnings"
                            titleColor="warning"
                          />
                        )}
                      </div>
                    ) : (
                      <div>
                        <h3 class="text-h6 primary--text mb-2">Status</h3>
                        <VIcon color="success" class="mr-2">
                          mdi-check-circle
                        </VIcon>
                        Conversation contains no errors or warnings.
                      </div>
                    )}
                  </VTabItem>
                </VTabsItems>
              </VCardText>
            </div>
          )}
        </VCard>
      </div>
    );
  },
});

export default DialLogDetail;
